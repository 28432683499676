import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/SignIn';
import Home from '~/pages/Home';
import MembersList from '~/pages/Members/List';
import MembersUpdate from '~/pages/Members/Update';
import Calendar from '~/pages/Calendar';
import FaqList from '~/pages/Faq';
import Profile from '~/pages/Profile';
import ProfileBio from '~/pages/Profile/Bio';
import ForgotPassword from '~/pages/ForgotPassword';
import Live from '~/pages/Live';

const Es: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/es/`} exact component={SignIn} />
      <Route
        path={`${process.env.PUBLIC_URL}/es/dashboard`}
        component={Home}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/members`}
        exact
        component={MembersList}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/members/:userID`}
        exact
        component={MembersUpdate}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/calendar`}
        component={Calendar}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/faq`}
        component={FaqList}
        exact
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/profile`}
        component={Profile}
        exact
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/profile/bio/:userID`}
        component={ProfileBio}
        exact
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/forgot-password`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/live/:roomID`}
        exact
        isPrivate
        component={Live}
      />
    </Switch>
  );
};

export default Es;
