import React from 'react';

import { AuthProvider } from './Auth';
import { ChatsProvider } from './Chats';
import { LanguageProvider } from './Language';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ChatsProvider>
      <LanguageProvider>{children}</LanguageProvider>
    </ChatsProvider>
  </AuthProvider>
);

export default AppProvider;
