import styled from 'styled-components';
import { Form } from '@unform/web';
import List from 'react-chatview';

interface IContainer {
  minimized: boolean;
}

export const Container = styled.div<IContainer>`
  background-color: #202020;
  width: 350px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  overflow: hidden;
  margin-right: 15px;
  box-shadow: 0px 0px 8px 0px #121212;
  margin-top: 10px;

  :first-child {
    margin-left: 15px;
  }

  .header {
    position: relative;

    .btn-minimize {
      pointer-events: ${(props) => (props.minimized ? 'none' : 'unset')};
    }
  }

  .content {
    padding: ${(props) => (props.minimized ? '0' : '10px')} 20px;
    height: ${(props) => (props.minimized ? '0' : '300px')};
    overflow: ${(props) => (props.minimized ? 'hidden' : 'auto')};
  }

  .footer {
    padding: ${(props) => (props.minimized ? '0' : '10px')} 20px;
    height: ${(props) => (props.minimized ? '0' : '78px')};
  }
`;

export const Header = styled.div`
  background-color: #242526;
  border: none;

  button {
    color: #cbccce;

    p {
      margin: 0;
    }

    .alert-message {
      background-color: #fdfdfd;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #212529;
      font-weight: 700;
      font-size: 80%;
      border-radius: 50%;
      align-self: center;
      margin-left: 5px;
    }
  }

  .btn-maximize {
    width: 100%;
    text-align: left;
    padding: 15px 20px;
  }

  .btn-minimize {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
  }

  .btn-close {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const Content = styled(List)`
  transition-duration: 0.3s;

  ::-webkit-scrollbar {
    background-color: #202020;
  }

  p {
    color: #cbccce;
    max-width: 75%;
    width: max-content;
    padding: 10px 20px;
    border-radius: 10px;
    position: relative;
    margin-bottom: 10px;
  }

  .me {
    background-color: #292929;
    margin-left: auto;

    :last-child {
      :after {
        content: '';
        background: transparent;
        border-radius: 2px;
        position: absolute;
        right: -7px;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 9px 14px 9px;
        border-color: transparent transparent #292929 transparent;
      }

      margin-bottom: 0;
    }
  }

  .you {
    background-color: #18191a;

    :last-child {
      :after {
        content: '';
        background: transparent;
        border-radius: 2px;
        position: absolute;
        left: -7px;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 9px 14px 9px;
        border-color: transparent transparent #18191a transparent;
      }

      margin-bottom: 0;
    }
  }
`;

export const Footer = styled(Form)`
  transition-duration: 0.3s;
  background-color: #292929;
  display: flex;
  align-items: center;
  overflow: hidden;

  .textarea {
    border: none;

    textarea {
      resize: none;
      position: relative;

      ::placeholder {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;
