import styled from 'styled-components';

export const Container = styled.div`
  overflow: auto;
  width: max-content;
  max-width: 100%;
  margin-left: auto;

  ::-webkit-scrollbar {
    background-color: #202020;
  }

  ::-webkit-scrollbar-thumb {
    background: #cbccce;
  }

  > div {
    width: max-content;
    min-width: 100%;
  }
`;
