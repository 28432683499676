import React, { useCallback } from 'react';

import { useChats } from '~/hooks/Chats';

import { Container } from './styles';
import Chat from './Chat';

const FloatChats: React.FC = () => {
  const { chats, setChats } = useChats();

  const handleRemove = useCallback(
    (chatIndex) => {
      const newChats = chats.slice();
      newChats.splice(chatIndex, 1);
      setChats(newChats);
    },
    [chats, setChats]
  );

  return (
    <Container className="fixed-bottom">
      <div className="d-flex justify-content-end align-items-end">
        {chats.map((chat, index) => (
          <Chat
            key={chat.id}
            data={chat}
            onRemove={() => handleRemove(index)}
          />
        ))}
      </div>
    </Container>
  );
};

export default FloatChats;
