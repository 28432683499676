import React, { createContext, useCallback, useState, useContext } from 'react';

export interface IMessage {
  id: string;
  user_id: string;
  content: string;
}

export interface IMessagesData {
  data: IMessage[];
  last_page: number;
  total: number;
}

export interface IChat {
  id: string;
  name: string;
  messagesData: IMessagesData;
}

interface ChatsContextData {
  chats: IChat[];
  setChats(chats: IChat[]): void;
}

export const ChatsContext = createContext<ChatsContextData>(
  {} as ChatsContextData
);

export const ChatsProvider: React.FC = ({ children }) => {
  const [chats, setChats] = useState<IChat[]>([]);

  const handleSetChat = useCallback((chatsData: IChat[]) => {
    setChats(chatsData);
  }, []);

  return (
    <ChatsContext.Provider value={{ chats, setChats: handleSetChat }}>
      {children}
    </ChatsContext.Provider>
  );
};

export function useChats(): ChatsContextData {
  const context = useContext(ChatsContext);

  if (!context) {
    throw new Error('useChats must be used within an ChatsProvider');
  }

  return context;
}
