import React, {
  useEffect,
  useState,
  useRef,
  Fragment,
  useMemo,
  useCallback,
} from 'react';
import {
  parseISO,
  format,
  lastDayOfMonth,
  getDaysInMonth,
  subDays,
  addDays,
  getDay,
  startOfDay,
  isEqual,
  getMonth,
  addMonths,
  subMonths,
} from 'date-fns';

import api from '~/services/api';

import {
  Container,
  Welcome,
  DateInput,
  CalendarDays,
  Modal,
  Skeleton,
} from './styles';

import btnPrev from '~/assets/icons/btnPrev.svg';
import { useLanguage } from '~/hooks/Language';

interface IMeet {
  id: string;
  date: string;
  user: {
    id: string;
    name: string;
  };
}

interface IDay {
  day: number;
  sunday: boolean;
  current: boolean;
  currentMonth: boolean;
  call: string;
  meets: IMeet[];
}

const Calendar: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { language } = useLanguage();
  const [daySelected, setDaySelected] = useState<IDay>({} as IDay);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(format(new Date(), 'yyyy'));
  const [show, setShow] = useState(false);
  const [days, setDays] = useState<IDay[]>([]);
  const [meets, setMeets] = useState<IMeet[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    api
      .get('appointments/me/monthly', {
        params: {
          month: parseInt(selectedMonth.toString(), 10) + 1,
          year: selectedYear,
        },
      })
      .then((response) => {
        setMeets(response.data);
      })
      .finally(() => setLoading(false));
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    const totalDaysInSelectedDate = getDaysInMonth(selectedDate);

    const lastDay = lastDayOfMonth(selectedDate);

    const firstDay = subDays(lastDay, totalDaysInSelectedDate - 1);
    const firstDayWeekPosition = getDay(firstDay);

    const initDay = subDays(firstDay, firstDayWeekPosition);

    let day = initDay.getDate();
    let totalDays = getDaysInMonth(initDay);

    const daysData: IDay[] = [];
    for (let i = 0; i < 42; i += 1) {
      if (day > totalDays) {
        day = 1;
        totalDays = getDaysInMonth(selectedDate);
      }

      const calls = meets.filter(
        // eslint-disable-next-line no-loop-func
        (meet) =>
          getMonth(parseISO(meet.date)) === getMonth(selectedDate) &&
          parseISO(meet.date).getDate() === day
      );

      const dayData = {
        day,
        sunday: getDay(addDays(initDay, i)) === 0,
        current: isEqual(addDays(initDay, i), startOfDay(new Date())),
        currentMonth: getMonth(addDays(initDay, i)) === getMonth(selectedDate),
        call: calls.length > 0 ? 'yes' : 'no',
        meets: calls,
      };

      daysData.push(dayData);
      day += 1;
    }

    setDays(daysData);
  }, [meets, selectedDate]);

  const months = useMemo(
    () => [
      {
        value: 0,
        label: language.calendar.month_1,
        selected: selectedMonth === 0,
      },
      {
        value: 1,
        label: language.calendar.month_2,
        selected: selectedMonth === 1,
      },
      {
        value: 2,
        label: language.calendar.month_3,
        selected: selectedMonth === 2,
      },
      {
        value: 3,
        label: language.calendar.month_4,
        selected: selectedMonth === 3,
      },
      {
        value: 4,
        label: language.calendar.month_5,
        selected: selectedMonth === 4,
      },
      {
        value: 5,
        label: language.calendar.month_6,
        selected: selectedMonth === 5,
      },
      {
        value: 6,
        label: language.calendar.month_7,
        selected: selectedMonth === 6,
      },
      {
        value: 7,
        label: language.calendar.month_8,
        selected: selectedMonth === 7,
      },
      {
        value: 8,
        label: language.calendar.month_9,
        selected: selectedMonth === 8,
      },
      {
        value: 9,
        label: language.calendar.month_10,
        selected: selectedMonth === 9,
      },
      {
        value: 10,
        label: language.calendar.month_11,
        selected: selectedMonth === 10,
      },
      {
        value: 11,
        label: language.calendar.month_12,
        selected: selectedMonth === 11,
      },
    ],
    [
      language.calendar.month_1,
      language.calendar.month_10,
      language.calendar.month_11,
      language.calendar.month_12,
      language.calendar.month_2,
      language.calendar.month_3,
      language.calendar.month_4,
      language.calendar.month_5,
      language.calendar.month_6,
      language.calendar.month_7,
      language.calendar.month_8,
      language.calendar.month_9,
      selectedMonth,
    ]
  );

  const years = useMemo(
    () => [
      { value: '2000', label: '2000', selected: selectedYear === '2000' },
      { value: '2001', label: '2001', selected: selectedYear === '2001' },
      { value: '2002', label: '2002', selected: selectedYear === '2002' },
      { value: '2003', label: '2003', selected: selectedYear === '2003' },
      { value: '2004', label: '2004', selected: selectedYear === '2004' },
      { value: '2005', label: '2005', selected: selectedYear === '2005' },
      { value: '2006', label: '2006', selected: selectedYear === '2006' },
      { value: '2007', label: '2007', selected: selectedYear === '2007' },
      { value: '2008', label: '2008', selected: selectedYear === '2008' },
      { value: '2009', label: '2009', selected: selectedYear === '2009' },
      { value: '2010', label: '2010', selected: selectedYear === '2010' },
      { value: '2011', label: '2011', selected: selectedYear === '2011' },
      { value: '2012', label: '2012', selected: selectedYear === '2012' },
      { value: '2013', label: '2013', selected: selectedYear === '2013' },
      { value: '2014', label: '2014', selected: selectedYear === '2014' },
      { value: '2015', label: '2015', selected: selectedYear === '2015' },
      { value: '2016', label: '2016', selected: selectedYear === '2016' },
      { value: '2017', label: '2017', selected: selectedYear === '2017' },
      { value: '2018', label: '2018', selected: selectedYear === '2018' },
      { value: '2019', label: '2019', selected: selectedYear === '2019' },
      { value: '2020', label: '2020', selected: selectedYear === '2020' },
      { value: '2021', label: '2021', selected: selectedYear === '2021' },
      { value: '2022', label: '2022', selected: selectedYear === '2022' },
      { value: '2023', label: '2023', selected: selectedYear === '2023' },
      { value: '2024', label: '2024', selected: selectedYear === '2024' },
      { value: '2025', label: '2025', selected: selectedYear === '2025' },
      { value: '2026', label: '2026', selected: selectedYear === '2026' },
      { value: '2027', label: '2027', selected: selectedYear === '2027' },
      { value: '2028', label: '2028', selected: selectedYear === '2028' },
      { value: '2029', label: '2029', selected: selectedYear === '2029' },
      { value: '2030', label: '2030', selected: selectedYear === '2030' },
      { value: '2031', label: '2031', selected: selectedYear === '2031' },
      { value: '2032', label: '2032', selected: selectedYear === '2032' },
      { value: '2033', label: '2033', selected: selectedYear === '2033' },
      { value: '2034', label: '2034', selected: selectedYear === '2034' },
      { value: '2035', label: '2035', selected: selectedYear === '2035' },
      { value: '2036', label: '2036', selected: selectedYear === '2036' },
      { value: '2037', label: '2037', selected: selectedYear === '2037' },
      { value: '2038', label: '2038', selected: selectedYear === '2038' },
      { value: '2039', label: '2039', selected: selectedYear === '2039' },
      { value: '2040', label: '2040', selected: selectedYear === '2040' },
      { value: '2041', label: '2041', selected: selectedYear === '2041' },
      { value: '2042', label: '2042', selected: selectedYear === '2042' },
      { value: '2043', label: '2043', selected: selectedYear === '2043' },
      { value: '2044', label: '2044', selected: selectedYear === '2044' },
      { value: '2045', label: '2045', selected: selectedYear === '2045' },
      { value: '2046', label: '2046', selected: selectedYear === '2046' },
      { value: '2047', label: '2047', selected: selectedYear === '2047' },
      { value: '2048', label: '2048', selected: selectedYear === '2048' },
      { value: '2049', label: '2049', selected: selectedYear === '2049' },
      { value: '2050', label: '2050', selected: selectedYear === '2050' },
    ],
    [selectedYear]
  );

  const handleChangeMonth = useCallback(
    (e) => {
      setDays([]);
      const month = e.target.options[e.target.selectedIndex].value;
      setSelectedMonth(month);
      setSelectedDate(new Date(parseInt(selectedYear, 10), month));
    },
    [selectedYear]
  );

  const handleChangeYear = useCallback(
    (e) => {
      setDays([]);
      const year = e.target.options[e.target.selectedIndex].value;
      setSelectedYear(year);
      setSelectedDate(new Date(year, selectedMonth));
    },
    [selectedMonth]
  );

  const dayView = useCallback((day: IDay) => {
    setShow(true);
    setDaySelected(day);
  }, []);

  const handleClose = () => setShow(false);

  const handleClick = useCallback(
    (action) => {
      let date;
      if (action === 'prev') {
        date = subMonths(selectedDate, 1);
      } else {
        date = addMonths(selectedDate, 1);
      }
      setDays([]);
      setSelectedMonth(date.getMonth());
      setSelectedYear(format(date, 'yyyy'));
      setSelectedDate(date);
    },
    [selectedDate]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h4 h2-sm mt-4 mb-5 ">
                      {language.calendar.h1}
                    </h1>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
        </div>
      </div>
      <div className="container-fluid container-xxl">
        <div className="row align-items-center">
          <div className="col-md-6 text-center text-md-left mt-4 mt-lg-0">
            <DateInput
              className={`${
                loading === true ? 'skeleton' : 'd-flex'
              } justify-content-center justify-content-md-start`}
            >
              <select
                className={`${
                  loading && 'color-transparent'
                } bg-transparent font-weight-bold border-0 h4 h1-lg`}
                onChange={handleChangeMonth}
              >
                {months.map((month) => (
                  <option
                    className="font-weight-bold"
                    key={month.label}
                    value={month.value}
                    selected={month.selected}
                  >
                    {month.label}
                  </option>
                ))}
              </select>
              <select
                className={`${
                  loading && 'color-transparent'
                } bg-transparent font-weight-bold border-0 h4 h1-lg`}
                onChange={handleChangeYear}
              >
                {years.map((year) => (
                  <option
                    className="font-weight-bold"
                    key={year.label}
                    value={year.value}
                    selected={year.selected}
                  >
                    {year.label}
                  </option>
                ))}
              </select>
            </DateInput>
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className={
                  loading === true
                    ? 'skeleton btn-skeleton rounded-circle mb-2 mb-md-0'
                    : 'h2 mb-0 mb-sm-2 bg-transparent btn-prev border-0'
                }
                onClick={() => handleClick('prev')}
              >
                <img src={btnPrev} alt={language.calendar.button_1} />
              </button>
              <button
                type="button"
                className={
                  loading === true
                    ? 'skeleton btn-skeleton rounded-circle mb-2 mb-md-0 ml-3'
                    : 'ml-3 mb-0 mb-sm-2 h2 bg-transparent border-0 btn-next'
                }
                onClick={() => handleClick('next')}
              >
                <img src={btnPrev} alt={language.calendar.button_2} />
              </button>
            </div>
          </div>
          <div className="col-12 px-0">
            <div className="row w-100 mt-3 mt-sm-5 mx-0">
              <div className="col-1-5 pr-lg-0 text-center">
                <p
                  className={
                    loading === true
                      ? 'skeleton px-1 px-sm-2'
                      : 'color-day d-flex justify-content-center'
                  }
                >
                  {language.calendar.p_1}
                  <span className="d-none d-lg-block">
                    {language.calendar.span_1}
                  </span>
                </p>
              </div>
              <div className="col-1-5 pr-lg-0 text-center">
                <p
                  className={
                    loading === true
                      ? 'skeleton px-1 px-sm-2'
                      : 'color-day d-flex justify-content-center'
                  }
                >
                  {language.calendar.p_2}
                  <span className="d-none d-lg-block">
                    {language.calendar.span_2}
                  </span>
                </p>
              </div>
              <div className="col-1-5 pr-lg-0 text-center">
                <p
                  className={
                    loading === true
                      ? 'skeleton px-1 px-sm-2'
                      : 'color-day d-flex justify-content-center'
                  }
                >
                  {language.calendar.p_3}
                  <span className="d-none d-lg-block">
                    {language.calendar.span_3}
                  </span>
                </p>
              </div>
              <div className="col-1-5 pr-lg-0 text-center">
                <p
                  className={
                    loading === true
                      ? 'skeleton px-1 px-sm-2'
                      : 'color-day d-flex justify-content-center'
                  }
                >
                  {language.calendar.p_4}
                  <span className="d-none d-lg-block">
                    {language.calendar.span_4}
                  </span>
                </p>
              </div>
              <div className="col-1-5 pr-lg-0 text-center">
                <p
                  className={
                    loading === true
                      ? 'skeleton px-1 px-sm-2'
                      : 'color-day d-flex justify-content-center'
                  }
                >
                  {language.calendar.p_5}
                  <span className="d-none d-lg-block">
                    {language.calendar.span_5}
                  </span>
                </p>
              </div>
              <div className="col-1-5 pr-lg-0 text-center">
                <p
                  className={
                    loading === true
                      ? 'skeleton px-1 px-sm-2'
                      : 'color-day d-flex justify-content-center'
                  }
                >
                  {language.calendar.p_6}
                  <span className="d-none d-lg-block">
                    {language.calendar.span_6}
                  </span>
                </p>
              </div>
              <div className="col-1-5 pr-lg-0 text-center">
                <p
                  className={
                    loading === true
                      ? 'skeleton px-1 px-sm-2'
                      : 'color-day d-flex justify-content-center'
                  }
                >
                  {language.calendar.p_7}
                  <span className="d-none d-lg-block">
                    {language.calendar.span_7}
                  </span>
                </p>
              </div>
            </div>
            {loading && (
              <Skeleton className="row w-100 mt-lg-3 justify-content-center mx-0 mb-4">
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="sunday other day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="  other day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className=" sunday  day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className=" sunday  day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="current   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className=" sunday  day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className=" sunday  day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="   day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="  other day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="  other day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className=" sunday other day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="  other day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="  other day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="  other day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="  other day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="  other day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
                <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                  <div className="  other day number-day p-xxl-4 p-md-3 p-lg-3 p-0">
                    <p className="skeleton text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                      00
                    </p>
                  </div>
                </div>
              </Skeleton>
            )}

            {!loading && (
              <CalendarDays
                className="row w-100 mt-lg-3 justify-content-center mx-0 mb-4"
                ref={ref}
              >
                {days.map((day) => (
                  <Fragment key={`${day.day}-${day.currentMonth}`}>
                    {day.call === 'yes' ? (
                      <>
                        <div className="col-1-5 d-flex align-items-center text-center pr-lg-0 mb-lg-3 mb-0">
                          <button
                            type="button"
                            className={`${day.current ? 'current' : ''} ${
                              day.sunday ? 'sunday' : ''
                            } ${
                              !day.currentMonth ? 'other' : ''
                            } day p-xxl-4 p-md-3 p-lg-3 p-0 w-100 row mx-0 border-0 call`}
                            // onClick={() => dayView(index + 1, day)}
                            onClick={() => dayView(day)}
                          >
                            <div className="col-12 px-0">
                              <p className="text-md-left number-day text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                                {day.day}
                              </p>
                            </div>
                            <div className="col-12 px-0 align-self-end d-none d-md-block">
                              <div className="d-flex">
                                {day.meets.map((meet, index) => (
                                  <Fragment key={meet.id}>
                                    {index < 3 && (
                                      <div key={meet.id} className="dot mr-2" />
                                    )}
                                  </Fragment>
                                ))}
                                {day.meets.length > 3 && (
                                  <div className="special-dot">
                                    <small>+{day.meets.length - 3}</small>
                                  </div>
                                )}
                              </div>
                            </div>
                          </button>
                        </div>
                        {/* <div className="col-12">teste</div> */}
                      </>
                    ) : (
                      <div className="col-1-5 text-center pr-lg-0 mb-lg-3 mb-0">
                        <div
                          className={`${day.current ? 'current' : ''} ${
                            day.sunday ? 'sunday' : ''
                          } ${
                            !day.currentMonth ? 'other' : ''
                          } day number-day p-xxl-4 p-md-3 p-lg-3 p-0`}
                        >
                          <p className="text-md-left text-center my-2 my-md-0 my-lg-0 h5 font-weight-bold">
                            {day.day}
                          </p>
                        </div>
                      </div>
                    )}
                  </Fragment>
                ))}
              </CalendarDays>
            )}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h3 className="h4 h3-lg font-weight-normal">
            {language.calendar.h3}{' '}
            <span className="font-weight-bold">
              {daySelected ? daySelected.day : ''}
            </span>
          </h3>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(daySelected).length > 0 && (
            <>
              {daySelected.meets.map((meet) => (
                <div className="d-flex mb-2 meet-group">
                  <div className="decoration-meet" />
                  <div>
                    <h5 className="font-weight-normal">
                      {language.calendar.h5} {meet.user.name}
                    </h5>
                    <p className="mb-0">
                      {format(parseISO(meet.date), 'hh:mm b')}
                    </p>
                  </div>
                </div>
              ))}
            </>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Calendar;
