import styled from 'styled-components';

export const Container = styled.div`
  #room-container {
    height: calc(100% - 50px);
  }

  .video-content {
    background-color: #242526;
    border-radius: 10px;

    video {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #18191a;
      transition-duration: 0.3s;
      opacity: 1;
    }

    .hide {
      opacity: 0;
    }

    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      background-color: #333;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        margin: 0;
        padding: 5px;
        color: #e4e4e4;
        font-size: 64px;
      }
    }

    .username {
      transition-duration: 0.3s;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      bottom: 10px;
      left: 10px;
      width: 150px;
      border-radius: 50px;
      background-color: #333;
      z-index: 1;

      p {
        margin: 0;
        padding: 5px;
        color: #e4e4e4;
      }
    }

    :hover {
      .username {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .host {
    height: calc(100% - 230px);

    .video-content {
      height: 100%;

      .avatar {
        width: 100px;
        height: 100px;
      }
    }
  }

  .audience {
    .video-content {
      height: 200px;

      .avatar {
        width: 75px;
        height: 75px;
      }
    }
  }

  .buttons {
    button {
      width: 48px;
      height: 48px;
      background-color: #606060;
      border: none;
      border-radius: 50px;
      color: #e4e4e4 !important;
      font-weight: bold;
      text-align: center;
    }
  }
`;
