import { darken } from 'polished';
import styled from 'styled-components';

interface IAvatarProps {
  src: string;
}

export const Container = styled.div`
  h2,
  input {
    color: #cbccce !important;
    font-weight: 400 !important;
  }

  label {
    color: #828282;
    font-weight: 400 !important;
  }

  .profile {
    background: #1f1f1f;
    border-radius: 20px;
    hr {
      background-color: #242526;
      height: 1px;
    }
  }

  .btn-call-student {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #212529;
    border-radius: 50px;
    font-weight: 600;
    background-color: #fdfdfd;

    :hover {
      background-color: ${darken(0.1, '#fdfdfd')};
    }
  }

  @media (min-width: 768px) and (max-width: 1399px) {
    .bd-right {
      border-right: solid 2px #242526;
    }
  }

  @media screen and (max-width: 450px) {
    .font-xs-14 {
      font-size: 14px;
    }
  }
`;

export const Welcome = styled.div`
  p {
    color: #969696;
  }

  h1 {
    color: #e4e4e4;
  }
`;

export const Profile = styled.div`
  img {
    padding: 3px;
    background-image: linear-gradient(0deg, #202020, #202020),
      radial-gradient(
        circle at top left,
        rgba(228, 228, 228, 0.5),
        rgba(228, 228, 228, 0.5)
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 50%;
  }

  span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #ffbb38;
    right: 5px;
    top: 8px;
  }

  p {
    color: rgba(247, 247, 247, 0.5);
  }
`;

export const Avatar = styled.div<IAvatarProps>`
  margin: 0 auto;
  width: 114px;
  height: 114px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 4px rgba(228, 228, 228, 0.5);
  box-sizing: border-box;
`;

export const PersonalInformation = styled.div`
  background-color: #202020;
  border-radius: 25px;

  .color-gray {
    color: #828282;
    span {
      color: #e4e4e4;
    }
  }

  .percent {
    color: #f7f7f7;
  }

  .btn-class button {
    color: #8c8c8c;
    font-size: 14px;
    font-weight: 700;
  }
  .courses {
    .col-md-4 {
      background-color: rgba(36, 37, 38, 1);
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }
    .col-md-8 {
      background-color: rgba(41, 41, 41, 1);
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
    .progress {
      background-color: rgba(70, 70, 70, 1);
      .progress-bar {
        background-color: rgba(171, 171, 171, 1);
      }
    }
    .unlock {
      border: none;
      border-radius: 25px;
      background-color: rgba(96, 96, 96, 1);
    }
    .locked {
      border: none;
      border-radius: 25px;
      background-color: rgba(96, 96, 96, 1);
      filter: brightness(0.5);
    }
  }
  label {
    color: #828282;
  }
  input {
    min-height: 35px;
    background-color: transparent;
  }

  .submit-button {
    background-color: #4a4b4b;
    border: none;
    border-radius: 20px;
    color: #e4e4e4 !important;
    text-align: center;

    :hover {
      text-decoration: none;
    }
  }

  .btn-radio {
    color: #cbccce;

    :hover {
      color: #cbccce;
    }
  }

  .btn-active {
    background-color: #4a4b4b;
    border-radius: 15px;
  }

  .btn-inactive {
    background-color: #18191a;
    border: 1px solid #262626;
    border-radius: 15px;
  }

  .percentage {
    top: 1px;
    right: 1px;
    border-left: 1.5px solid #3e3e3e;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: transparent;
    height: calc(100% - 2px);
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8c8c8c;
  }
  @media screen and (max-width: 1281px) {
    .col-update {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    .order-settings {
      order: -1;
    }
    .courses {
      .col-md-4 {
        border-top-right-radius: 25px;
        border-bottom-left-radius: 0;
      }
      .col-md-8 {
        border-top-right-radius: 0;
        border-bottom-left-radius: 25px;
      }
    }
  }

  @media screen and (max-width: 450px) {
    .btn-class button {
      font-size: 12px;
    }
  }
`;

export const Skeleton = styled.div`
  .input-h {
    height: 59px;
  }
  .skeleton {
    background-color: #343434 !important;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #343434 0%,
        rgba(24, 26, 26, 0) 0%,
        rgba(24, 26, 26, 0) 10%,
        rgba(24, 26, 26, 0.2) 20%,
        rgba(24, 26, 26, 0.5) 40%,
        rgba(24, 26, 26, 0.8) 50%,
        rgba(24, 26, 26, 0.2) 80%,
        rgba(24, 26, 26, 0) 90%,
        rgba(24, 26, 26, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;
