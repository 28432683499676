import React, { useCallback, useEffect, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { FaSearch } from 'react-icons/fa';

import api from '~/services/api';

import { Container, Welcome, Question, ChatButton, Skeleton } from './styles';

import chat from '~/assets/icons/chat-icon.svg';
import noCourse from '~/assets/icons/no-purchased-course-icon.svg';
import { useLanguage } from '~/hooks/Language';

interface IFaq {
  id: string;
  question: string;
  answer: string;
}

const Faq: React.FC = () => {
  const { language } = useLanguage();
  const [faq, setFaq] = useState<IFaq[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get('faq/coaches')
      .then((response) => {
        setFaq(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSearch = useCallback(async (e) => {
    const response = await api.get('faq/students', {
      params: {
        question: e.target.value,
      },
    });

    if (response.data) {
      setFaq(response.data);
    }
  }, []);

  const handleClickQuestion = useCallback((e) => {
    const questionBox = e.target.closest('.question-box');
    if (questionBox) {
      questionBox.classList.toggle('active');
    }
  }, []);

  return (
    <Container className="mt-n4 text-center text-md-left">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-12 p-0">
            <Welcome className="pl-md-5 mb-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-12 px-0 py-5 px-md-3">
                    <h1 className="h2">{language.faq.h1}</h1>
                  </div>
                  <div className="col-md-6 col-lg-4 pb-5">
                    <div className="search d-flex mb-3 mb-md-0 align-items-center">
                      <input
                        onChange={handleSearch}
                        placeholder={language.faq.placeholder}
                        className="w-100"
                      />
                      <FaSearch className="mr-3" color="#cbccce" />
                    </div>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
        </div>
      </div>
      <div className="container pb-lg-5">
        <div className="row pb-5">
          {loading && (
            <>
              <Skeleton className="col-md-6 col-lg-4 px-lg-0 px-xxl-3">
                <div className="question-box p-2">
                  <button
                    type="button"
                    className="skeleton d-flex justify-content-between align-items-center py-3 px-4 question w-100 border-0"
                  >
                    <p className="text-left mb-0 max-width">####### # ####</p>
                  </button>
                </div>
              </Skeleton>
              <Skeleton className="col-md-6 col-lg-4 px-lg-0 px-xxl-3">
                <div className="question-box p-2">
                  <button
                    type="button"
                    className="skeleton d-flex justify-content-between align-items-center py-3 px-4 question w-100 border-0"
                  >
                    <p className="text-left mb-0 max-width">####### # ####</p>
                  </button>
                </div>
              </Skeleton>
              <Skeleton className="col-md-6 col-lg-4 px-lg-0 px-xxl-3">
                <div className="question-box p-2">
                  <button
                    type="button"
                    className="skeleton d-flex justify-content-between align-items-center py-3 px-4 question w-100 border-0"
                  >
                    <p className="text-left mb-0 max-width">####### # ####</p>
                  </button>
                </div>
              </Skeleton>
              <Skeleton className="col-md-6 col-lg-4 px-lg-0 px-xxl-3">
                <div className="question-box p-2">
                  <button
                    type="button"
                    className="skeleton d-flex justify-content-between align-items-center py-3 px-4 question w-100 border-0"
                  >
                    <p className="text-left mb-0 max-width">####### # ####</p>
                  </button>
                </div>
              </Skeleton>
              <Skeleton className="col-md-6 col-lg-4 px-lg-0 px-xxl-3">
                <div className="question-box p-2">
                  <button
                    type="button"
                    className="skeleton d-flex justify-content-between align-items-center py-3 px-4 question w-100 border-0"
                  >
                    <p className="text-left mb-0 max-width">####### # ####</p>
                  </button>
                </div>
              </Skeleton>
              <Skeleton className="col-md-6 col-lg-4 px-lg-0 px-xxl-3">
                <div className="question-box p-2">
                  <button
                    type="button"
                    className="skeleton d-flex justify-content-between align-items-center py-3 px-4 question w-100 border-0"
                  >
                    <p className="text-left mb-0 max-width">####### # ####</p>
                  </button>
                </div>
              </Skeleton>
            </>
          )}

          {faq.length > 0 ? (
            <>
              {faq.map((question) => (
                <Question className="col-md-6 col-lg-4 px-lg-0 px-xxl-3">
                  <div className="question-box p-2">
                    <button
                      type="button"
                      className="d-flex justify-content-between align-items-center py-3 px-4 question w-100 border-0"
                      onClick={handleClickQuestion}
                    >
                      <p className="text-left mb-0 max-width">
                        {question.question}
                      </p>
                      <FiChevronDown />
                    </button>
                    <div className="answer px-4">
                      <p className="small mb-0 pr-2">{question.answer}</p>
                    </div>
                  </div>
                </Question>
              ))}
            </>
          ) : (
            <div className={`${loading && 'd-none'} col-12`}>
              <div className="row h-40vh align-items-center justify-content-center">
                <div className="col-md-8 d-md-flex text-center justify-content-md-center align-items-center">
                  <img
                    src={noCourse}
                    className="mb-3 mb-md-0"
                    alt={language.faq.img_1}
                  />
                  <p className="h5 mb-0 pl-md-3 text-no-courses text-center">
                    {language.faq.p}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ChatButton
        href="mailto:feedback@attomlabs.com"
        className="rounded-circle d-flex align-items-center justify-content-center"
      >
        <img src={chat} alt="chat" />
      </ChatButton>
    </Container>
  );
};

export default Faq;
