import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  BsFillCameraVideoFill,
  BsFillCameraVideoOffFill,
  BsFillMicFill,
  BsFillMicMuteFill,
  BsPauseCircle,
  BsArrowUpCircle,
} from 'react-icons/bs';

import api from '~/services/api';

import {
  initializePeersEvents,
  initializeSocketEvents,
  reInitializeStream,
  getMyVideo,
  mute,
  show,
  toggleVideoTrack,
  unMute,
  unShow,
  joinRoom,
} from '~/services/connection';

import {
  getMyVideoScreen,
  initializePeersEventsScreen,
  initializeSocketEventsScreen,
  joinRoomScreen,
  reInitializeStreamScreen,
  toggleVideoTrackScreen,
} from '~/services/connection/shareScreen';

import { Container } from './styles';

interface IParams {
  roomID: string;
}

interface IUserDetails {
  avatar: string;
  host: boolean;
  name: string;
}

const Live: React.FC = () => {
  const params = useParams<IParams>();
  const [socketConnected, setSocketConnected] = useState(false);
  const [peerConnected, setPeerConnected] = useState(false);
  const [peerConnectedScreen, setPeerConnectedScreen] = useState(false);
  const [me, setMe] = useState('');
  const [camStatus, setCamStatus] = useState(false);
  const [micStatus, setMicStatus] = useState(true);
  const [sharedScreen, setSharedScreen] = useState(false);
  const [userDetails, setUserDetails] = useState<IUserDetails>(
    {} as IUserDetails
  );

  useEffect(() => {
    initializeSocketEvents(setSocketConnected);
    initializePeersEvents(setMe, setPeerConnected);
    show();
    unShow();
    mute();
    unMute();

    initializeSocketEventsScreen();
    initializePeersEventsScreen(setPeerConnectedScreen);
  }, []);

  useEffect(() => {
    if (socketConnected && peerConnected && peerConnectedScreen) {
      api.get(`appointments/${params.roomID}`).then((response) => {
        setUserDetails(response.data);
        setMe(response.data.user_id);
        joinRoom(true, true, params.roomID, response.data);
        joinRoomScreen(true, false, `${params.roomID}-screen`, response.data);
      });
    }
  }, [params.roomID, peerConnected, peerConnectedScreen, socketConnected]);

  useEffect(() => {
    const myVideo = getMyVideoScreen() as any;
    if (myVideo && myVideo.srcObject) {
      myVideo.srcObject.getVideoTracks().forEach((track: any) => {
        const trackData = track;
        trackData.onended = () => {
          toggleVideoTrackScreen({ video: camStatus, audio: micStatus });
          reInitializeStreamScreen(camStatus, micStatus, 'userMedia'); // Active Cam
          setSharedScreen(!sharedScreen);
        };
      });
    }
  }, [camStatus, micStatus, sharedScreen]);

  const handleClickScreenShare = useCallback(async () => {
    if (!sharedScreen) {
      toggleVideoTrackScreen({ video: false, audio: micStatus });
      await reInitializeStreamScreen(false, micStatus, 'displayMedia'); // Shared Screen
    } else {
      toggleVideoTrackScreen({ video: camStatus, audio: micStatus });
      await reInitializeStreamScreen(camStatus, micStatus, 'userMedia'); // Active Cam
    }

    setSharedScreen(!sharedScreen);
  }, [camStatus, micStatus, sharedScreen]);

  const handleClickCamera = useCallback(async () => {
    if (params.roomID) {
      toggleVideoTrack({ video: !camStatus, audio: micStatus });
      if (!camStatus) {
        await api.put(`lives/${params.roomID}`, {
          peer_id: me,
          type: 'show',
          micStatus,
          userDetails,
        });
      } else {
        await api.put(`lives/${params.roomID}`, {
          peer_id: me,
          type: 'unshow',
          micStatus,
          userDetails,
        });
      }
      setCamStatus(!camStatus);
    }
  }, [camStatus, me, micStatus, params.roomID, userDetails]);

  const handleClickMic = useCallback(() => {
    if (params.roomID) {
      const myVideo = getMyVideo(me);
      if (myVideo && myVideo.srcObject) {
        const srcObject = myVideo.srcObject as MediaStream;
        srcObject.getAudioTracks().forEach(async (track: any) => {
          if (track.kind === 'audio') {
            if (micStatus) {
              await api.put(`lives/${params.roomID}`, {
                peer_id: me,
                type: 'mute',
                camStatus,
              });
              track.stop();
            } else {
              await api.put(`lives/${params.roomID}`, {
                peer_id: me,
                type: 'unmute',
                camStatus,
              });
              reInitializeStream(camStatus, !micStatus);
            }
          }
        });
        setMicStatus(!micStatus);
      }
    }
  }, [camStatus, me, micStatus, params.roomID]);

  return (
    <Container className="container h-100">
      <div id="room-container" className="row" />
      <div className="row justify-content-center buttons">
        <div className="col-lg-8">
          <div className="row justify-content-center buttons">
            <div className="col-lg-1 text-center">
              <button type="button" onClick={handleClickCamera}>
                {camStatus ? (
                  <BsFillCameraVideoFill size={22} color="#e4e4e4" />
                ) : (
                  <BsFillCameraVideoOffFill size={22} color="#e4e4e4" />
                )}
              </button>
            </div>
            <div className="col-lg-1 text-center">
              <button type="button" onClick={handleClickMic}>
                {micStatus ? (
                  <BsFillMicFill size={22} color="#e4e4e4" />
                ) : (
                  <BsFillMicMuteFill size={22} color="#e4e4e4" />
                )}
              </button>
            </div>
            <div className="col-lg-1 text-center">
              <button type="button" onClick={handleClickScreenShare}>
                {sharedScreen ? (
                  <BsPauseCircle size={22} color="#e4e4e4" />
                ) : (
                  <BsArrowUpCircle size={22} color="#e4e4e4" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Live;
