import styled from 'styled-components';
import { lighten } from 'polished';

interface IAvatar {
  src: string;
}
interface IBio {
  src: string;
  alt: string;
}

export const Container = styled.div`
  .border-gray {
    border-color: #3e3e3e;
    border-radius: 15px !important;
  }

  .height {
    height: 206px;
  }

  .skeleton {
    background-color: #343434 !important;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    .language {
      background: transparent !important;
    }

    span,
    input,
    textarea {
      color: transparent !important;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #343434 0%,
        rgba(24, 26, 26, 0) 0%,
        rgba(24, 26, 26, 0) 10%,
        rgba(24, 26, 26, 0.2) 20%,
        rgba(24, 26, 26, 0.5) 40%,
        rgba(24, 26, 26, 0.8) 50%,
        rgba(24, 26, 26, 0.2) 80%,
        rgba(24, 26, 26, 0) 90%,
        rgba(24, 26, 26, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;

export const Welcome = styled.div`
  p {
    color: #969696;
  }
  h1 {
    color: #e4e4e4;
  }
`;

export const Avatar = styled.label<IAvatar>`
  margin: 0 auto;
  width: 114px;
  height: 114px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 4px rgba(228, 228, 228, 0.5);
  box-sizing: border-box;
  position: relative;

  > div {
    position: absolute;
    background-color: #606060;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    right: 0;
  }
`;

export const ContactInfo = styled.div`
  background: #1f1f1f;
  border-radius: 20px;
  padding: 20px;

  h3 {
    font-size: 18px !important;
    color: #cbccce;
  }

  label {
    color: #828282;
  }

  input {
    height: 43px;
    color: #cbccce !important;
    font-weight: 400;
  }
  @media (min-width: 768px) and (max-width: 1399px) {
    .border-contact {
      border-right: 1px solid #3e3e3e;
    }
  }
`;

export const PersonalInformation = styled.div`
  background: #1f1f1f;
  border-radius: 20px;
  padding: 35px 30px;

  h3 {
    color: #cbccce !important;
  }

  input {
    height: 43px;
    color: #cbccce !important;
    font-weight: 400;
  }

  textarea {
    resize: none;
    color: #cbccce !important;
    font-weight: 400;
  }

  .bg-gray {
    background: #242526;
    border-radius: 20px;
    overflow: hidden;

    p {
      color: #565656;
    }
  }

  label {
    color: #828282;
  }

  .h-96px {
    min-height: 96px;
    textarea {
      height: 60px;
    }
  }

  .h-168px {
    textarea {
      min-height: 168px;
    }
  }

  .bio-photo {
    width: 100%;
    border-radius: 20px;
  }

  .language-input {
    textarea {
      padding: 0 10px;
      height: 75px;
      resize: none;
      border-radius: 15px !important;
    }
  }

  .my-bio {
    background-color: #242526;
    border: none;
    border-radius: 15px;
    color: #e4e4e4 !important;
    font-weight: bold;
    text-align: center;

    :hover {
      text-decoration: none;
      background-color: ${lighten(0.1, '#242526')};
    }
  }

  .submit-button {
    background-color: #606060;
    border: none;
    border-radius: 15px;
    color: #e4e4e4 !important;
    font-weight: bold;
    text-align: center;

    :hover {
      text-decoration: none;
      background-color: ${lighten(0.1, '#606060')};
    }
  }

  @media screen and (max-width: 991px) {
    padding: 35px 20px;
  }
`;

export const BioPhoto = styled.div<IBio>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
`;
