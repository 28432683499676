import React, { useCallback, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import Input from '~/components/Input';

interface InputDateProps {
  name?: string;
  onChange(date: Date | [Date, Date] | null): void;
  selected?: Date | null;
  className?: string;
  dateFormat?: string | string[];
  isError?: boolean;
}

const InputDate: React.FC<InputDateProps> = ({
  name,
  onChange,
  selected,
  className,
  dateFormat,
  isError,
}) => {
  registerLocale('es', es);
  const [value, setValue] = useState('');

  const handleChange = useCallback(
    (e) => {
      setValue(e);
      onChange(e);
    },
    [onChange]
  );

  const { lang } = document.documentElement;

  return (
    <>
      <DatePicker
        onChange={handleChange}
        selected={selected}
        className={`${className} ${isError ? 'error-input' : ''}`}
        dateFormat={dateFormat || 'dd/MM/yyyy'}
        locale={lang}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
      {name && (
        <Input type="hidden" name={name} className="d-none" value={value} />
      )}
    </>
  );
};

export default InputDate;
