import Peer from 'peerjs';

import socket from '~/services/socket';

interface IUserData {
  roomID: string;
  userID: string;
  user_id: string;
  name: string;
  avatar: string;
  host: boolean;
  video: boolean;
  audio: boolean;
  type: 'displayMedia' | 'userMedia';
}

interface ICreateVideo {
  id: string;
  stream: MediaStream;
  userData: IUserData;
}

interface MediaStatus {
  video: boolean;
  audio: boolean;
}

let me = '';
let mePeerId = '';
const peers = {} as any;
const videos: any = [];
let dataUser = {} as IUserData;

const peer = new Peer(undefined, {
  host: process.env.REACT_APP_PEER_HOST,
  port: parseInt(process.env.REACT_APP_PEERCAM_PORT as string, 10),
  path: 'cam',
});

export const createVideo = async (createObj: ICreateVideo): Promise<void> => {
  if (!videos[createObj.id]) {
    videos[createObj.id] = {
      ...createObj,
    };
    const roomContainer = document.getElementById(
      'room-container'
    ) as HTMLElement;
    const videoContainerElement = document.createElement('div');
    videoContainerElement.id = `video-${createObj.id}`;
    if (createObj.userData.host) {
      videoContainerElement.className = 'col-lg-12 order-0 mb-2 host';
    } else if (me === createObj.id) {
      videoContainerElement.className = 'col-lg-3 order-2 mt-2 audience';
    } else {
      videoContainerElement.className = 'col-lg-3 order-3 mt-2 audience';
    }

    const videoContentElement = document.createElement('button');
    videoContentElement.id = `cam-${createObj.id}`;
    videoContentElement.className =
      'w-100 video-content border-0 d-flex justify-content-center align-items-center position-relative';

    const avatarContainer = document.createElement('div');
    avatarContainer.className = 'avatar';

    if (createObj.userData) {
      if (createObj.userData.avatar) {
        avatarContainer.style.backgroundImage = `url('${createObj.userData.avatar}')`;
      } else {
        const firstLetter = createObj.userData.name.slice(0, 1).toUpperCase();
        const pElement = document.createElement('p');
        pElement.innerText = firstLetter;
        avatarContainer.appendChild(pElement);
      }
    }

    const nameContainer = document.createElement('div');
    nameContainer.className = 'username';
    if (createObj.userData) {
      const pElement = document.createElement('p');
      pElement.innerText = createObj.userData.name;
      nameContainer.appendChild(pElement);
    }

    const video = document.createElement('video');
    video.srcObject = await videos[createObj.id].stream;
    video.id = createObj.id;
    video.className = 'w-100 h-100 hide';
    video.autoplay = true;
    if (me === createObj.id) {
      video.muted = true;
    }

    videoContentElement.appendChild(avatarContainer);
    videoContentElement.appendChild(nameContainer);
    videoContentElement.appendChild(video);
    videoContainerElement.appendChild(videoContentElement);
    roomContainer.append(videoContainerElement);
  } else {
    const element = document.getElementById(createObj.id) as HTMLVideoElement;
    if (createObj.userData) {
      if (createObj.userData.type === 'displayMedia') {
        const roomContainer = document.getElementById(
          'room-container'
        ) as HTMLElement;
        const videoContainerElement = document.createElement('div');
        videoContainerElement.className = createObj.userData.host
          ? 'col-lg-12 order-0 mb-2 host shared-screen'
          : 'col-lg-3 order-3 mt-2 audience shared-screen';

        const videoContentElement = document.createElement('button');
        videoContentElement.className =
          'w-100 video-content border-0 d-flex justify-content-center align-items-center position-relative';
        videoContainerElement.id = `video-shared-screen-${createObj.id}`;

        const avatarContainer = document.createElement('div');
        avatarContainer.className = 'avatar';

        if (createObj.userData) {
          if (createObj.userData.avatar) {
            avatarContainer.style.backgroundImage = `url('${createObj.userData.avatar}')`;
          } else {
            const firstLetter = createObj.userData.name
              .slice(0, 1)
              .toUpperCase();
            const pElement = document.createElement('p');
            pElement.innerText = firstLetter;
            avatarContainer.appendChild(pElement);
          }
        }

        const nameContainer = document.createElement('div');
        nameContainer.className = 'username';
        if (createObj.userData) {
          const pElement = document.createElement('p');
          pElement.innerText = createObj.userData.name;
          nameContainer.appendChild(pElement);
        }

        const video = document.createElement('video');
        video.srcObject = createObj.stream;
        video.id = `shared-screen-${createObj.id}`;
        video.className = 'w-100 h-100';
        video.autoplay = true;
        if (me === createObj.id) {
          video.muted = true;
        }

        videoContentElement.appendChild(avatarContainer);
        videoContentElement.appendChild(nameContainer);
        videoContentElement.appendChild(video);
        videoContainerElement.appendChild(videoContentElement);
        roomContainer.append(videoContainerElement);
      } else if (createObj.userData.video) {
        element.classList.remove('hide');
      } else {
        element.classList.add('hide');
      }
    }
    element.srcObject = await createObj.stream;
  }
};

export const removeVideo = (id: string): void => {
  delete videos[id];
  const videoContainer = document.getElementById(`video-${id}`);
  if (videoContainer) {
    videoContainer.remove();
  }
};

export const getVideoAudioStream = (
  video = true,
  audio = true
): Promise<MediaStream> => {
  const quality = 12;
  const { mediaDevices } = navigator as any;
  const myNavigator =
    mediaDevices.getUserMedia ||
    mediaDevices.webkitGetUserMedia ||
    mediaDevices.mozGetUserMedia ||
    mediaDevices.msGetUserMedia;

  return myNavigator({
    video: video
      ? {
          frameRate: quality || 12,
        }
      : false,
    audio: audio
      ? {
          noiseSuppression: true,
        }
      : false,
  });
};

const connectToNewUser = (userData: IUserData, stream: MediaStream) => {
  const { userID, user_id } = userData;
  if (user_id !== me) {
    const call = peer.call(userID, stream, { metadata: dataUser });
    call.on('stream', (userVideoStream: MediaStream) => {
      createVideo({
        id: user_id,
        stream: userVideoStream,
        userData: {
          ...userData,
          video: false,
          audio: true,
          type: 'userMedia',
        },
      });
    });
    call.on('close', () => {
      // console.log('closing new user', user_id);
      removeVideo(user_id);
    });
    call.on('error', () => {
      // console.log('peer error ------');
      removeVideo(user_id);
    });
    peers[user_id] = call;
  }
};

const setPeersListeners = (stream: MediaStream) => {
  peer.on('call', (call) => {
    call.answer(stream);
    call.on('stream', (userVideoStream: MediaStream) => {
      createVideo({
        id: call.metadata.user_id,
        stream: userVideoStream,
        userData: call.metadata,
      });
    });
    call.on('close', () => {
      // console.log('closing peers listeners', call.metadata.user_id);
      removeVideo(call.metadata.user_id);
    });
    call.on('error', () => {
      // console.log('peer error ------');
      removeVideo(call.metadata.user_id);
    });
    peers[call.metadata.user_id] = call;
  });
};

const newUserConnection = (stream: MediaStream) => {
  socket.on('new-user-connect', (userData) => {
    // console.log('New User Connected', userData);
    // connectToNewUser(userData, stream);
    setTimeout(connectToNewUser, 200, userData, stream);
  });
};

const setNavigatorToStream = async (
  video: boolean,
  audio: boolean,
  userData: IUserData
) => {
  const stream: MediaStream = await getVideoAudioStream(video, audio);
  if (stream) {
    createVideo({
      id: me,
      stream,
      userData,
    });
    setPeersListeners(stream);
    newUserConnection(stream);
    listenToEndStream(stream, { video, audio });
  }
};

export const initializeSocketEvents = (
  setSocketConnected: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  socket.on('connect', () => {
    setSocketConnected(true);
    // console.log('socket connected');
  });
  socket.on('user-disconnected', (userData) => {
    // console.log('user disconnected-- closing peers', userData);
    peers[userData.userID] && peers[userData.userID].close();
    removeVideo(userData.user_id);
  });
  socket.on('disconnect', () => {
    // console.log('socket disconnected --');
  });
  socket.on('error', (err) => {
    // console.log('socket error --', err);
  });
  socket.on('new-broadcast-messsage', (data: any) => {
    // message.push(data);
    // settings.updateInstance('message', message);
    // eslint-disable-next-line no-alert
    alert(`${data.message.message} By ${data.userData.name}`);
  });
};

export const initializePeersEvents = (
  setMe: React.Dispatch<React.SetStateAction<string>>,
  setPeerConnected: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  peer.on('open', async (id) => {
    mePeerId = id;
    setPeerConnected(true);
    // console.log('peers established');
  });
  peer.on('error', (err) => {
    // console.log('peer connection error', JSON.stringify(err));
    // peer.reconnect();
  });
};

export const joinRoom = async (
  video: boolean,
  audio: boolean,
  roomID: string,
  userDetails: {
    user_id: string;
    name: string;
    avatar: string;
    host: boolean;
  }
): Promise<void> => {
  const userData: IUserData = {
    userID: mePeerId,
    roomID,
    video: false,
    audio: true,
    ...userDetails,
    type: 'userMedia',
  };
  me = userDetails.user_id;
  // console.log('joined room', userData);
  socket.emit('join-room', userData);
  await setNavigatorToStream(video, audio, userData);
  dataUser = userData;
};

export const show = async (): Promise<void> => {
  socket.on('show', (userData) => {
    // console.log('User show cam', userData);
    const video = getMyVideo('', userData.peer_id);
    if (video) {
      video.classList.remove('hide');
      const newUserData: IUserData = {
        roomID: userData.room_id,
        userID: userData.peer_id,
        user_id: userData.user_id,
        name: userData.userDetails.name,
        avatar: userData.userDetails.avatar,
        host: userData.userDetails.host,
        video: true,
        audio: userData.micStatus,
        type: 'userMedia',
      };
      reInitializeStream(
        true,
        userData.micStatus,
        'userMedia',
        userData.peer_id,
        newUserData
      );
    }
  });
};

export const unShow = async (): Promise<void> => {
  socket.on('unshow', (userData) => {
    // console.log('User unshow cam', userData);
    const video = getMyVideo('', userData.peer_id);
    if (video) {
      video.classList.add('hide');
      if (video.srcObject) {
        (<MediaStream>video.srcObject)
          .getVideoTracks()
          .forEach((track: any) => {
            if (track.kind === 'video') {
              track.stop();
            }
          });
      }
    }
  });
};

export const mute = async (): Promise<void> => {
  socket.on('mute', (userData) => {
    // console.log('User muted', userData);
    const video = getMyVideo('', userData.peer_id);
    if (video) {
      if (video.srcObject) {
        (<MediaStream>video.srcObject)
          .getAudioTracks()
          .forEach((track: any) => {
            if (track.kind === 'audio') {
              track.stop();
            }
          });
      }
    }
  });
};

export const unMute = async (): Promise<void> => {
  socket.on('unmute', (userData) => {
    // console.log('User unmuted', userData);
    const video = getMyVideo('', userData.peer_id);
    // console.log(video);
    if (video) {
      if (video.srcObject) {
        (<MediaStream>video.srcObject)
          .getAudioTracks()
          .forEach((track: any) => {
            if (track.kind === 'audio') {
              reInitializeStream(
                userData.camStatus,
                true,
                'userMedia',
                userData.peer_id
              );
            }
          });
      }
    }
  });
};

export const getMyVideo = (prefix = '', id = me): HTMLVideoElement => {
  return document.getElementById(`${prefix}${id}`) as HTMLVideoElement;
};

export const toggleVideoTrack = (status: MediaStatus): void => {
  const myVideo = getMyVideo();
  if (myVideo && !status.video) {
    myVideo.classList.add('hide');

    if (myVideo.srcObject) {
      (<MediaStream>myVideo.srcObject)
        .getVideoTracks()
        .forEach((track: any) => {
          if (track.kind === 'video') {
            !status.video && track.stop();
          }
        });
    }
  } else if (myVideo) {
    // console.log('AQYE');
    reInitializeStream(status.video, status.audio);
  }
};

export const checkAndAddClass = (video?: any, type = 'userMedia'): void => {
  // console.log(video);
  const newVideo = video;
  if (newVideo) {
    if (type === 'displayMedia') {
      video.classList.remove('hide');
    } else {
      video.classList.add('hide');
    }
  }
};

export const replaceStream = (mediaStream: MediaStream): void => {
  Object.values(peers).forEach((peerData: any) => {
    // console.log(peerData.peerConnection?.getSenders());
    peerData.peerConnection?.getSenders().forEach(async (sender: any) => {
      if (sender.track.kind === 'audio') {
        if (mediaStream.getAudioTracks().length > 0) {
          sender.replaceTrack(mediaStream.getAudioTracks()[0]);
        }
      }
      if (sender.track.kind === 'video') {
        if (mediaStream.getVideoTracks().length > 0) {
          await sender.replaceTrack(mediaStream.getVideoTracks()[0]);
        }
      }
    });
  });
};

export const reInitializeStream = (
  video: boolean,
  audio: boolean,
  type: 'userMedia' | 'displayMedia' = 'userMedia',
  id = me,
  userData = dataUser
): Promise<any> => {
  const newUserData = userData;
  newUserData.video = video;
  newUserData.audio = audio;
  newUserData.type = type;
  const { mediaDevices } = navigator as any;
  const media =
    type === 'userMedia'
      ? getVideoAudioStream(video, audio)
      : mediaDevices.getDisplayMedia();
  return new Promise((resolve) => {
    media.then((stream: MediaStream) => {
      const myVideo = getMyVideo('', id);
      if (type === 'displayMedia') {
        if (myVideo.srcObject) {
          stream.addTrack((<MediaStream>myVideo.srcObject).getAudioTracks()[0]);
          stream.addTrack((<MediaStream>myVideo.srcObject).getVideoTracks()[0]);
        }
        toggleVideoTrack({ audio, video });
        listenToEndStream(stream, { video, audio }, userData.user_id);
      } else if (!video) {
        toggleVideoTrack({ audio, video });
      }

      createVideo({
        id,
        stream,
        userData: newUserData,
      }).then(() => {
        replaceStream(<MediaStream>myVideo.srcObject);
        socket.emit('display-media', {
          value: true,
          user_id: userData.user_id,
        });
      });

      resolve(true);
    });
  });
};

export const listenToEndStream = (
  stream: MediaStream,
  status: MediaStatus,
  user_id = me
): void => {
  const videoTrack = stream.getVideoTracks();
  // console.log(videoTrack);
  if (videoTrack[0]) {
    videoTrack[0].onended = () => {
      // console.log('AQUI');
      socket.emit('display-media', { value: false, user_id });
      reInitializeStream(status.video, status.audio, 'userMedia');
      toggleVideoTrack(status);
    };
  }
};
