import Peer from 'peerjs';

import socket from '~/services/socket';

interface IUserData {
  roomID: string;
  userID: string;
  user_id: string;
  name: string;
  avatar: string;
  host: boolean;
  video: boolean;
  audio: boolean;
  type: 'displayMedia' | 'userMedia';
}

interface ICreateVideo {
  id: string;
  stream: MediaStream;
  userData: IUserData;
}

interface MediaStatus {
  video: boolean;
  audio: boolean;
}

let me = '';
let mePeerId = '';
const peers = {} as any;
const videos: any = [];
let dataUser = {} as IUserData;

const peerScreen = new Peer(undefined, {
  host: process.env.REACT_APP_PEER_HOST,
  port: parseInt(process.env.REACT_APP_PEERSCREEN_PORT as string, 10),
  path: 'screen',
});

const createVideoScreen = async (createObj: ICreateVideo): Promise<void> => {
  const videoID = createObj.id.replace('-screen', '');
  if (!videos[createObj.id]) {
    videos[createObj.id] = {
      ...createObj,
    };
    const roomContainer = document.getElementById(
      'room-container'
    ) as HTMLElement;
    const newVideoContainerElement = document.createElement('div');
    newVideoContainerElement.id = `screenvideo-${videoID}`;
    newVideoContainerElement.className =
      'col-lg-3 order-6 mt-2 audience d-none';

    const videoContentElement = document.createElement('button');
    videoContentElement.id = `screen-${createObj.id}`;
    videoContentElement.className =
      'w-100 video-content border-0 d-flex justify-content-center align-items-center position-relative';

    const avatarContainer = document.createElement('div');
    avatarContainer.className = 'avatar';

    if (createObj.userData) {
      if (createObj.userData.avatar) {
        avatarContainer.style.backgroundImage = `url('${createObj.userData.avatar}')`;
      } else {
        const firstLetter = createObj.userData.name.slice(0, 1).toUpperCase();
        const pElement = document.createElement('p');
        pElement.innerText = firstLetter;
        avatarContainer.appendChild(pElement);
      }
    }

    const nameContainer = document.createElement('div');
    nameContainer.className = 'username';
    if (createObj.userData) {
      const pElement = document.createElement('p');
      pElement.innerText = createObj.userData.name;
      nameContainer.appendChild(pElement);
    }

    const video = document.createElement('video');
    video.srcObject = await videos[createObj.id].stream;
    video.id = createObj.id;
    video.className = 'w-100 h-100 hide';
    video.autoplay = true;
    video.muted = true;

    videoContentElement.appendChild(avatarContainer);
    videoContentElement.appendChild(nameContainer);
    videoContentElement.appendChild(video);
    newVideoContainerElement.appendChild(videoContentElement);
    roomContainer.append(newVideoContainerElement);
  } else {
    const element = document.getElementById(createObj.id) as HTMLVideoElement;
    if (element) {
      element.srcObject = await createObj.stream;
    }
  }
};

const removeVideoScreen = (id: string): void => {
  delete videos[id];
  const videoContainer = document.getElementById(`screenvideo-${id}`);
  if (videoContainer) {
    videoContainer.remove();
  }
};

const getVideoAudioStreamScreen = (
  video = true,
  audio = true
): Promise<MediaStream> => {
  const quality = 12;
  const { mediaDevices } = navigator as any;
  const myNavigator =
    mediaDevices.getUserMedia ||
    mediaDevices.webkitGetUserMedia ||
    mediaDevices.mozGetUserMedia ||
    mediaDevices.msGetUserMedia;

  return myNavigator({
    video: video
      ? {
          frameRate: quality || 12,
        }
      : false,
    audio: audio
      ? {
          noiseSuppression: true,
        }
      : false,
  });
};

const connectToNewUser = (userData: IUserData, stream: MediaStream) => {
  const { userID, user_id } = userData;
  if (user_id !== me) {
    const call = peerScreen.call(userID, stream, { metadata: dataUser });
    call.on('stream', (userVideoStream: MediaStream) => {
      createVideoScreen({
        id: user_id,
        stream: userVideoStream,
        userData: {
          ...userData,
          video: false,
          audio: true,
          type: 'userMedia',
        },
      });
    });
    call.on('close', () => {
      // console.log('closing new user', user_id);
      removeVideoScreen(user_id);
    });
    call.on('error', () => {
      // console.log('peerScreen error ------');
      removeVideoScreen(user_id);
    });
    peers[user_id] = call;
  }
};

const setPeersListeners = (stream: MediaStream) => {
  peerScreen.on('call', (call) => {
    call.answer(stream);
    call.on('stream', (userVideoStream: MediaStream) => {
      createVideoScreen({
        id: `${call.metadata.user_id}-screen`,
        stream: userVideoStream,
        userData: call.metadata,
      });
    });
    call.on('close', () => {
      // console.log('closing peers listeners', call.metadata.user_id);
      removeVideoScreen(call.metadata.user_id);
    });
    call.on('error', () => {
      // console.log('peerScreen error ------');
      removeVideoScreen(call.metadata.user_id);
    });
    peers[call.metadata.user_id] = call;
  });
};

const newUserConnection = (stream: MediaStream) => {
  socket.on('new-user-connect', (userData) => {
    // console.log('New User Connected', userData);
    const newUserData = {
      ...userData,
      user_id: `${userData.user_id}-screen`,
    };
    setTimeout(connectToNewUser, 200, newUserData, stream);
  });
};

const setNavigatorToStream = async (
  video: boolean,
  audio: boolean,
  userData: IUserData
) => {
  const stream: MediaStream = await getVideoAudioStreamScreen(video, audio);
  if (stream) {
    createVideoScreen({
      id: me,
      stream,
      userData,
    });
    setPeersListeners(stream);
    newUserConnection(stream);
    listenToEndStreamScreen(stream, { video, audio });
  }
};

export const initializeSocketEventsScreen = (): void => {
  socket.on('user-disconnected', (userData) => {
    // console.log('user disconnected-- closing peers', userData);
    peers[userData.userID] && peers[userData.userID].close();
    removeVideoScreen(userData.user_id);
  });
  socket.on('display-media-front', (data: any) => {
    const user_id = data.user_id.replace('-screen', '');
    checkAndAddClassScreen(user_id, data.value);
  });
};

export const initializePeersEventsScreen = (
  setPeerConnected: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  peerScreen.on('open', async (id) => {
    mePeerId = id;
    setPeerConnected(true);
    // console.log('peers established');
  });
  peerScreen.on('error', (err) => {
    // console.log('peerScreen connection error', err);
    // peerScreen.reconnect();
  });
};

export const joinRoomScreen = async (
  video: boolean,
  audio: boolean,
  roomID: string,
  userDetails: {
    user_id: string;
    name: string;
    avatar: string;
    host: boolean;
  }
): Promise<void> => {
  const userData: IUserData = {
    userID: mePeerId,
    roomID,
    video: false,
    audio: true,
    ...userDetails,
    type: 'userMedia',
  };
  me = `${userDetails.user_id}-screen`;
  // console.log('joined room', userData);
  socket.emit('join-room', userData);
  await setNavigatorToStream(video, audio, userData);
  dataUser = userData;
};

export const getMyVideoScreen = (prefix = '', id = me): HTMLVideoElement => {
  return document.getElementById(`${prefix}${id}`) as HTMLVideoElement;
};

export const toggleVideoTrackScreen = (status: any): void => {
  const myVideo = getMyVideoScreen() as any;
  if (myVideo && !status.video) {
    // console.log(myVideo);
    myVideo.srcObject?.getVideoTracks().forEach((track: any) => {
      if (track.kind === 'video') {
        !status.video && track.stop();
      }
    });
  } else if (myVideo) {
    reInitializeStreamScreen(status.video, status.audio);
  }
};

const checkAndAddClassScreen = (videoID: string, shared: boolean): void => {
  if (shared) {
    const videoContainerElement = document.getElementById(`video-${videoID}`);
    if (videoContainerElement) {
      const screenVideoContainerElement = document.getElementById(
        `screenvideo-${videoID}`
      );
      const hostElement = document.querySelector(`.host`);
      if (screenVideoContainerElement && hostElement) {
        const element = document.getElementById(
          `${videoID}-screen`
        ) as HTMLVideoElement;
        (<any>hostElement).classList = 'col-lg-3 order-1 mt-2 audience';
        (<any>screenVideoContainerElement).classList =
          'col-12 order-0 mb-2 host';
        element.classList.remove('hide');
        // element.srcObject = await createObj.stream;
      }
    }
  } else {
    const screenVideoContainerElement = document.getElementById(
      `screenvideo-${videoID}`
    );
    if (screenVideoContainerElement) {
      const videoContainerElement = document.getElementById(`video-${videoID}`);
      const hostElement = document.querySelector(`.host`);
      if (videoContainerElement && hostElement) {
        const element = document.getElementById(
          `${videoID}-screen`
        ) as HTMLVideoElement;
        (<any>hostElement).classList = 'col-lg-3 order-6 mt-2 audience d-none';
        (<any>videoContainerElement).classList = 'col-12 order-0 mb-2 host';

        element.classList.add('hide');
        // element.srcObject = await createObj.stream;
      }
    }
  }
};

const replaceStreamScreen = (mediaStream: MediaStream): void => {
  Object.values(peers).forEach((peerData: any) => {
    peerData.peerConnection?.getSenders().forEach((sender: any) => {
      // console.log(sender);
      if (sender.track.kind === 'audio') {
        if (mediaStream.getAudioTracks().length > 0) {
          sender.replaceTrack(mediaStream.getAudioTracks()[0]);
        }
      }
      if (sender.track.kind === 'video') {
        if (mediaStream.getVideoTracks().length > 0) {
          sender.replaceTrack(mediaStream.getVideoTracks()[0]);
        }
      }
    });
  });
};

export const reInitializeStreamScreen = (
  video: boolean,
  audio: boolean,
  type = 'userMedia',
  id = me,
  userData = dataUser
): Promise<any> => {
  const { mediaDevices } = navigator as any;
  const media =
    type === 'userMedia'
      ? getVideoAudioStreamScreen(video, audio)
      : mediaDevices.getDisplayMedia();
  return new Promise((resolve) => {
    media.then((stream: MediaStream) => {
      // console.log(id);
      const myVideo = getMyVideoScreen('', id);
      if (type === 'displayMedia') {
        toggleVideoTrackScreen({ audio, video });
        listenToEndStreamScreen(stream, { video, audio });
        socket.emit('display-media', {
          value: true,
          user_id: `${userData.user_id}-screen`,
        });
      } else {
        socket.emit('display-media', {
          value: false,
          user_id: `${userData.user_id}-screen`,
        });
      }
      checkAndAddClassScreen(userData.user_id, type === 'displayMedia');
      createVideoScreen({ id, stream, userData });
      replaceStreamScreen(stream);
      resolve(true);
    });
  });
};

const listenToEndStreamScreen = (
  stream: MediaStream,
  status: MediaStatus,
  user_id = me
): void => {
  const videoTrack = stream.getVideoTracks();
  // console.log(videoTrack);
  if (videoTrack[0]) {
    videoTrack[0].onended = () => {
      // console.log('AQUI');
      socket.emit('display-media', {
        value: false,
        user_id: `${user_id}-screen`,
      });
      reInitializeStreamScreen(status.video, status.audio, 'userMedia');
      toggleVideoTrackScreen(status);
    };
  }
};
