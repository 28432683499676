import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';

import api from '~/services/api';

import { Content } from './styles';

import options from '~/assets/icons/options.svg';
import timer from '~/assets/icons/timer.svg';
import { useLanguage } from '~/hooks/Language';

interface INotificationResponse {
  id: string;
  title: string;
  content: string;
  created_at: string;
}

interface INotification {
  id: string;
  title: string;
  content: string;
  date: string;
}

const Notifications: React.FC = () => {
  const { language } = useLanguage();
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get<INotificationResponse[]>('notifications')
      .then((response) => {
        const data: INotification[] = response.data.map((notification) => ({
          id: notification.id,
          title: notification.title,
          content: notification.content,
          date: format(parseISO(notification.created_at), 'MMM dd, yyyy'),
        }));

        setNotifications(data);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Content className="d-flex d-xxl-block h-100 pb-xxl-2">
      {loading && (
        <div className="skeleton notification no-notifications p-5 w-100 d-flex justify-content-center align-items-center">
          <p className="h6 mb-0">{language.component_notifications.p}</p>
        </div>
      )}
      {notifications.length > 0 ? (
        <>
          {notifications.map((notification) => (
            <div
              key={notification.id}
              className={`${
                loading === true ? 'd-none' : ''
              } notification-group pr-2 pb-2 pb-xxl-0`}
            >
              <div className="py-4 notification mr-3 mx-xxl-0">
                <h3 className="h6 font-weight-600 mb-0 py-1 px-3 px-xxl-4 d-flex justify-content-between">
                  {notification.date}
                  {/* <img src={options} alt="options" /> */}
                </h3>
                <p className="h6 font-weight-300 px-3 px-xxl-4 mt-4">
                  {notification.title}
                </p>
                <div className="d-flex timer mt-3 align-items-center px-3 px-xxl-4">
                  <img
                    src={timer}
                    alt={language.component_notifications.img_1}
                  />
                  <p className="mb-0 ml-3 font-weight-300">
                    {notification.content}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div
          className={`${
            loading === true ? 'd-none' : 'd-flex'
          } notification no-notifications p-5 w-100 justify-content-center align-items-center`}
        >
          <p className="h6 mb-0">{language.component_notifications.p}</p>
        </div>
      )}
    </Content>
  );
};

export default Notifications;
