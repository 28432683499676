import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/SignIn';
import Home from '~/pages/Home';
import MembersList from '~/pages/Members/List';
import MembersUpdate from '~/pages/Members/Update';
import Calendar from '~/pages/Calendar';
import FaqList from '~/pages/Faq';
import Profile from '~/pages/Profile';
import ProfileBio from '~/pages/Profile/Bio';
import ForgotPassword from '~/pages/ForgotPassword';
import Live from '~/pages/Live';

const En: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/en/`} exact component={SignIn} />
      <Route
        path={`${process.env.PUBLIC_URL}/en/dashboard`}
        component={Home}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/members`}
        exact
        component={MembersList}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/members/:userID`}
        exact
        component={MembersUpdate}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/calendar`}
        component={Calendar}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/faq`}
        component={FaqList}
        exact
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/profile`}
        component={Profile}
        exact
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/profile/bio/:userID`}
        component={ProfileBio}
        exact
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/forgot-password`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/live/:roomID`}
        exact
        isPrivate
        component={Live}
      />
    </Switch>
  );
};

export default En;
