import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  .btn-next {
    transform: rotate(180deg);
  }

  .col-1-5 {
    flex: 0 0 14.28%;
    max-width: 14.28%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .color-day {
    color: #8c8c8c;
  }
  .number-day {
    color: #cbccce;
  }

  .btn-prev,
  .btn-next {
    filter: brightness(0.5);
    :hover {
      filter: brightness(1.5);
    }
  }

  .color-transparent {
    color: transparent !important;
  }

  .btn-skeleton {
    width: 57px;
    height: 57px;
  }

  .skeleton {
    background-color: #343434 !important;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-flex;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #343434 0%,
        rgba(24, 26, 26, 0) 0%,
        rgba(24, 26, 26, 0) 10%,
        rgba(24, 26, 26, 0.2) 20%,
        rgba(24, 26, 26, 0.5) 40%,
        rgba(24, 26, 26, 0.8) 50%,
        rgba(24, 26, 26, 0.2) 80%,
        rgba(24, 26, 26, 0) 90%,
        rgba(24, 26, 26, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (max-width: 991px) {
    min-height: 100vh;
    .col-1-5 {
      padding-right: 0.75rem;
      padding-left: 0;
      padding-top: 0.75rem;
    }
  }

  @media screen and (max-width: 767px) {
    .col-1-5 {
      padding-right: 0;
      padding-left: 0;
      padding-top: 0;
    }
  }

  @media screen and (max-width: 575px) {
    .btn-prev,
    .btn-next {
      img {
        width: 40px;
        height: 40px;
      }
    }

    .btn-skeleton {
      width: 40px;
      height: 40px;
    }
  }
`;

export const Welcome = styled.div`
  p {
    color: #969696;
  }

  h1 {
    color: #e4e4e4;
  }
`;

export const DateInput = styled.div`
  select {
    color: #cbccce;
    option {
      background-color: rgba(32, 32, 32, 1);
      color: #cbccce;
    }
  }
`;

export const CalendarDays = styled.div`
  .day {
    height: 150px;
    background-color: rgba(32, 32, 32, 1);
    border-radius: 25px;
  }

  .current {
    background-color: rgba(96, 96, 96, 1) !important;
  }

  .sunday {
    background: #1c1c1d;
    border: 0.5px solid rgba(51, 51, 51, 0.5);
  }

  .other {
    opacity: 0.5;
  }

  .dot {
    background: rgba(255, 187, 56, 0.6);
    height: 20px;
    width: 20px;
    border-radius: 15px;
  }

  .special-dot {
    background: rgba(255, 187, 56, 0.3);
    border: 1px solid rgba(255, 187, 56, 0.6);
    height: 20px;
    width: 20px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    small {
      font-weight: 200;
      font-size: 10px;
    }
  }

  .current {
    .dot {
      background: rgba(255, 187, 56, 1);
      height: 20px;
    }
    .special-dot {
      background: rgba(255, 187, 56, 0.3);
      border: 1px solid rgba(255, 187, 56, 1);
    }
  }

  .day-row {
    background-color: rgba(36, 37, 38, 1);
    border-radius: 25px;
    min-height: 250px;
  }

  @media screen and (max-width: 1280px) {
    .day {
      height: 120px;
    }
    .day-row {
      min-height: 180px;
    }
  }

  @media screen and (max-width: 991px) {
    .dot,
    .special-dot {
      display: none;
    }

    .day {
      height: auto !important;
      border-radius: 10px;
      border: 0.5px solid rgba(32, 32, 32, 1);
    }

    .call {
      background-color: rgba(0, 138, 195, 1) !important;
    }
  }

  @media screen and (max-width: 767px) {
    .day {
      border-radius: 0;
      border: 0.5px solid #2f2f2f;
      p {
        font-size: 14px;
      }
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    h3,
    span {
      color: #e4e4e4 !important;
    }
    h5 {
      color: #cbccce;
    }
    border-radius: 15px;
  }
`;

export const Skeleton = styled.div`
  .day {
    height: 150px;
    background: #1c1c1d;
    border-radius: 25px;
    text-align: start;
  }
  .skeleton {
    background-color: #343434 !important;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #343434 0%,
        rgba(24, 26, 26, 0) 0%,
        rgba(24, 26, 26, 0) 10%,
        rgba(24, 26, 26, 0.2) 20%,
        rgba(24, 26, 26, 0.5) 40%,
        rgba(24, 26, 26, 0.8) 50%,
        rgba(24, 26, 26, 0.2) 80%,
        rgba(24, 26, 26, 0) 90%,
        rgba(24, 26, 26, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .day {
      height: 120px;
    }
  }

  @media screen and (max-width: 991px) {
    .day {
      height: auto !important;
      border-radius: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    p {
      font-size: 14px !important;
    }
    .day {
      text-align: center;
      border-radius: 0;
      border: 0.5px solid #2f2f2f;
      height: 35px !important;
    }
  }
`;
