import React, { useCallback } from 'react';
import { useLanguage } from '~/hooks/Language';

import { Container } from './styles';

interface ILesson {
  id: string;
  title: string;
  description: string;
  coach_unlock: string;
}

interface ICourse {
  id: string;
  title: string;
  lessons: ILesson[];
  selected: boolean;
}

interface ICoursesProps {
  courses: ICourse[];
  onSelectCourse?(course: ICourse): void;
}

const Courses: React.FC<ICoursesProps> = ({ courses, onSelectCourse }) => {
  const { language } = useLanguage();
  const handleClick = useCallback(
    (course) => {
      if (onSelectCourse) {
        onSelectCourse(course);
      }
    },
    [onSelectCourse]
  );

  return (
    <Container className="py-4 py-md-5 pl-md-3">
      <h2 className="h5 text-center text-md-left">
        {language.component_courses.h2}
      </h2>
      <div className="categories row mt-3 mt-md-4 mt-xl-5 text-left pl-3 mr-0">
        {courses.map((course) => (
          <div className="col-12 pl-1 mt-2">
            <button
              type="button"
              onClick={() => handleClick(course)}
              className={`w-100 px-3 px-lg-4 py-3 ${
                course.selected ? 'selected' : ''
              }`}
            >
              {course.title}
            </button>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Courses;
